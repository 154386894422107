import { makeRequest } from "@api/makeRequest";

import { parseAxiosError } from "@utils/parse-error";

import type { UserAccount } from "@frontend/kitui";
import type { OriginalUserAccount } from "@root/user";

type RecoverUserRequest = {
  password: string;
};

export const getUser = async (): Promise<[UserAccount | null, string?]> => {
  try {
    const user = await makeRequest<never, UserAccount>({
      method: "GET",
      url: `${process.env.REACT_APP_DOMAIN}/customer/v1.0.0/account`,
    });

    return [user];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось получить пользователя. ${error}`];
  }
};

export const getAccount = async (): Promise<
  [OriginalUserAccount | null, string?]
> => {
  try {
    const controller = new AbortController();

    const account = await makeRequest<never, OriginalUserAccount>({
      url: `${process.env.REACT_APP_DOMAIN}/squiz/account/get`,
      contentType: true,
      method: "GET",
      useToken: true,
      withCredentials: false,
      signal: controller.signal,
    });

    return [account];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось получить данные аккаунта. ${error}`];
  }
};

export const recoverUser = async (
  password: string,
): Promise<[unknown | null, string?]> => {
  try {
    const recoverResponse = await makeRequest<RecoverUserRequest, unknown>({
      url: `${process.env.REACT_APP_DOMAIN}/user`,
      method: "PATCH",
      body: { password },
    });

    return [recoverResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось восстановить пароль. ${error}`];
  }
};
