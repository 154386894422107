import { connectAmo } from "@/api/integration";
import { setTryShowAmoTokenExpiredDialog } from "@/stores/uiTools/actions";
import { useUiTools } from "@/stores/uiTools/store";
import CustomCheckbox from "@/ui_kit/CustomCheckbox";
import { Box, Button, Dialog, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const HIDE_DIALOG_EXPIRATION_PERIOD = 24 * 60 * 60 * 1000;

interface Props {
  isAmoTokenExpired: boolean;
}

export default function AmoTokenExpiredDialog({ isAmoTokenExpired }: Props) {
  const theme = useTheme();
  const tryShowAmoTokenExpiredDialog = useUiTools((state) => state.tryShowAmoTokenExpiredDialog);
  const [isHideDialogForADayChecked, setIsHideDialogForADayChecked] = useState<boolean>(false);
  const location = useLocation();

  const onAmoClick = async () => {
    const [url, error] = await connectAmo();
    if (url && !error) {
      window.open(url, "_blank");
    }
  };

  function handleDialogClose() {
    if (isHideDialogForADayChecked) {
      const expirationDate = Date.now() + HIDE_DIALOG_EXPIRATION_PERIOD;
      localStorage.setItem("hideAmoTokenExpiredDialogExpirationTime", expirationDate.toString());
    }

    setTryShowAmoTokenExpiredDialog(false);
  }

  useEffect(() => {
    setTryShowAmoTokenExpiredDialog(true);
  }, [location]);

  return (
    <Dialog
      open={isAmoTokenExpired && tryShowAmoTokenExpiredDialog}
      onClose={handleDialogClose}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          maxWidth: "620px",
        },
      }}
    >
      <Box
        sx={{
          p: "20px",
          backgroundColor: "#F2F3F7",
        }}
      >
        <Typography
          color="#4D4D4D"
          fontSize="24px"
          fontWeight="medium"
        >
          Ваш amo-токен не работает
        </Typography>
      </Box>
      <Box
        sx={{
          p: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <Typography color="#4D4D4D">
          Amo отозвал ваш токен. Зайдите заново в свой аккаунт, чтобы вам снова начали приходить сделки.
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column-reverse",
            },
          }}
        >
          <Button
            variant="outlined"
            onClick={handleDialogClose}
            sx={{
              flex: "1 0 0",
              borderColor: "#9A9AAF",
            }}
          >
            Позже
          </Button>
          <Button
            variant="contained"
            onClick={onAmoClick}
            sx={{
              flex: "1 0 0",
            }}
          >
            Перелогиниться
          </Button>
        </Box>
        <CustomCheckbox
          label={"Не показывать сутки"}
          checked={isHideDialogForADayChecked}
          handleChange={({ target }) => setIsHideDialogForADayChecked(target.checked)}
        />
      </Box>
    </Dialog>
  );
}
